import { useEffect, useRef, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyEuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, IconButton, InputAdornment, useTheme } from '@mui/material';
import { BillingDetails as AdminBillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { Money } from '@one/api-models/lib/Money';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';
import { PaymentProcessRequest } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentProcessRequest';
import { PaymentProcessResponse } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentProcessResponse';
import { PaymentType } from '@one/api-models/lib/Sales/Payment/Transaction/PaymentType';

import { ApiError } from 'apiAccess/api-client';
import ControlledTextField from 'common/ControlledTextField';
import { Loading } from 'components/_common/Loading';
import { AddPaymentMethodDialog } from 'components/_common/paymentPlan/AddPaymentMethodDialog';
import { useRetrievePaymentMethodList } from 'components/hooks/paymentHooks';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Customer } from 'models/customers/Customer';
import { PaymentProviderList } from 'models/PaymentProvider';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  SectionTitle,
  Typography,
} from 'styled';
import { formatAddress } from 'utils/address';

import { CustomerSelectorAutocomplete } from '../customers/components/CustomerSelectorAutocomplete';
import { BillingDetailsDialog } from '../sales/BillingDetailsDialog';

import { CardSelector } from './components/CardSelector';
import { PaymentMethodOption } from './components/EditPaymentPlanDialog';

interface TakePaymentDialogProps {
  orderNumber: string;
  paymentMethodId?: number;
  balance: Money;
  open: boolean;
  handleClose: (shouldRefetch: boolean) => void;
  testIdPrefix: string;
  defaultBillingDetails: BillingDetails;
  customer: Customer;
}
interface TakePaymentForm {
  amount: number;
  description?: string;
  notes?: string;
  paymentMethodReference?: string;
}

export const TakePaymentDialog = ({
  orderNumber,
  balance,
  open,
  handleClose,
  testIdPrefix,
  paymentMethodId,
  defaultBillingDetails,
  customer,
}: TakePaymentDialogProps) => {
  const theme = useTheme();
  const fromRef = useRef<HTMLFormElement>(null);
  const minPaymentAmount = 1;
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const [additionalPaymentMethodListOptions, setAdditionalPaymentMethodListOptions] = useState<PaymentMethodOption[]>(
    [],
  );
  const [selectedPaymentMethodReference, setSelectedPaymentMethodReference] = useState<PaymentMethodOption | undefined>(
    undefined,
  );
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);
  const [billingDetailsDialogOpen, setBillingDetailsDialogOpen] = useState<boolean>(false);
  const [billingDetails, setBillingDetails] = useState<BillingDetails>(defaultBillingDetails);
  const [forceBillingValidation, setForceBillingValidation] = useState<boolean>(false);

  const validationSchema: yup.SchemaOf<TakePaymentForm> = yup.object().shape({
    amount: yup
      .number()
      .typeError('Amount is required.')
      .required('Amount is required.')
      .min(minPaymentAmount, `Minimum amount is ${formatCurrency(minPaymentAmount, balance?.currency)}.`)
      .max(balance?.amount, `Maximum amount is ${formatCurrency(balance?.amount, balance?.currency)}.`),
    paymentMethodReference: yup.string().required('Payment method is required.'),
    description: yup.string().trim(),
    notes: yup.string().trim(),
  });

  const defaultValues: TakePaymentForm = {
    amount: 0,
    description: '',
    notes: '',
  };
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TakePaymentForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  const { isLoading: isProcessPaymentLoading, mutate: mutateProcessPayment } = useMutation<
    any,
    ApiError,
    PaymentProcessRequest,
    unknown
  >((request) => api.payment.processPayment(request), {
    mutationKey: 'processPaymentMutation',
    onSuccess: (value: PaymentProcessResponse) => {
      addMessage({
        label: 'Payment successfully processed.',
        severity: 'success',
      });
      handleClose(true);
    },
    onError: (error) => {
      apiErrorHandler(error);
    },
  });

  const {
    isFetching: isFetchingAdditionalPaymentMethodList,
    data: additionalPaymentMethodListData,
    refetch: refetchAdditionalPaymentMethodList,
  } = useRetrievePaymentMethodList(customer.memberKey, true);

  useEffect(() => {
    if (additionalPaymentMethodListData) {
      const additionalFormattedPaymentMethods = additionalPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: false, // TODO: check if this is correct
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
          billingDetails: i.billingDetails,
        }),
      );

      setAdditionalPaymentMethodListOptions(additionalFormattedPaymentMethods);
      const defaultPaymentMethod = paymentMethodId
        ? additionalFormattedPaymentMethods.find((i: PaymentMethodOption) => Number(i.code) === paymentMethodId)
        : additionalFormattedPaymentMethods[0];

      if (defaultPaymentMethod) {
        setValue('paymentMethodReference', defaultPaymentMethod?.code);
        setSelectedPaymentMethodReference(defaultPaymentMethod);
      }
    }
  }, [additionalPaymentMethodListData, paymentMethodId, setValue]);

  const mapGatewayIdentifierLabel = (gatewayIdentifier: string) => {
    const provider = PaymentProviderList.find((provider) => gatewayIdentifier.includes(provider));
    return provider ? provider : 'Unknown';
  };

  const refetchPaymentMethodList = () => {
    refetchAdditionalPaymentMethodList();
  };

  const handleChangePaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = additionalPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      setBillingDetails(selectedPaymentMethod.billingDetails ?? defaultBillingDetails);
      setSelectedPaymentMethodReference(selectedPaymentMethod);
    }
  };

  const handleTakePayment = (data: TakePaymentForm) => {
    if (
      !billingDetails ||
      (!!billingDetails &&
        (!billingDetails?.billingAddress?.countryCode ||
          !billingDetails?.billingAddress?.postalCode ||
          ((billingDetails?.billingAddress?.countryCode === 'US' ||
            billingDetails?.billingAddress?.countryCode === 'CA') &&
            !billingDetails?.billingAddress?.stateCode)))
    ) {
      setBillingDetailsDialogOpen(true);
      setForceBillingValidation(true);
    } else {
      const request: PaymentProcessRequest = {
        amountToPay: {
          amount: data.amount,
          currency: balance?.currency,
          isEstimated: false,
        },
        memberKey: customer.memberKey,
        orderNumber: orderNumber,
        description: data.description,
        notes: data.notes,
        paymentMethodId: Number(data.paymentMethodReference),
        paymentType: PaymentType.Adhoc,
        billingInfo: {
          billingDetails: billingDetails,
        },
      };

      mutateProcessPayment(request);
    }
  };

  const handleBillingDetailsChange = (data: AdminBillingDetails) => {
    setBillingDetails({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      billingAddress: {
        addressLine1: data.streetAddress,
        addressLine2: data.aptNumber,
        city: data.city,
        state: data.state,
        stateCode: data.stateCode,
        postalCode: data.zipCode,
        countryCode: data.country,
        country: data.country,
      },
    });
  };
  const handleCloseBillingDetailsDialog = () => {
    setBillingDetailsDialogOpen(false);
    setForceBillingValidation(false);
  };

  const mapCurrency = () => {
    switch (balance?.currency) {
      case 'USD':
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
      case 'GBP':
        return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
      case 'EUR':
        return <CurrencyEuroIcon sx={{ fontSize: '1rem' }} />;
      default:
        return undefined;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Take a Payment
        <IconButton
          onClick={() => handleClose(false)}
          size="small"
          disableRipple
          data-testid={`${testIdPrefix}CloseButton`}
        >
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isProcessPaymentLoading && <Loading message="Please wait while we process your payment..." />}
        <form onSubmit={handleSubmit(handleTakePayment)} ref={fromRef}>
          <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} md={4}>
              <ControlledTextField
                name="amount"
                control={control as unknown as Control<FieldValues, object>}
                fullWidth
                label="Amount"
                type="number"
                error={errors.amount?.message != null}
                helperText={errors.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{mapCurrency()}</InputAdornment>,
                  inputProps: {
                    step: 0.01,
                  },
                }}
                testId={`${testIdPrefix}Amount`}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="description"
                fullWidth
                label="Description"
                description="Optional. This will appear on the Customer Receipt"
                error={errors?.description?.message != null}
                helperText={errors?.description?.message}
                testId={`${testIdPrefix}Description`}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerSelectorAutocomplete
                testId={testIdPrefix}
                handleChange={() => {}}
                defaultValue={customer}
                disabled={true}
                label="Customer"
              />
            </Grid>
            <Grid item xs={12}>
              <CardSelector
                isLoading={isFetchingAdditionalPaymentMethodList}
                control={control as unknown as Control<FieldValues, object>}
                name="paymentMethodReference"
                label="Payment Method"
                options={additionalPaymentMethodListOptions}
                error={errors.paymentMethodReference != null}
                helperText={errors.paymentMethodReference?.message}
                requiredMessage="Payment method is required"
                disableClearable
                popperWidth="300px"
                onChange={(value) => {
                  handleChangePaymentMethod(value);
                }}
                getOptionDisabled={(option) => {
                  const currentDate = new Date();
                  return (
                    option.expirationYear < currentDate.getFullYear() ||
                    (option.expirationYear === currentDate.getFullYear() &&
                      option.expirationMonth < currentDate.getMonth())
                  );
                }}
                testId={`${testIdPrefix}PaymentMethod`}
              />
              <Button
                onClick={() => setAddPaymentModalOpen(true)}
                variant="text"
                startIcon={<AddIcon />}
                data-testid={`${testIdPrefix}AddPaymentMethodButton`}
                sx={{ mt: 0.5 }}
              >
                Add New
              </Button>
            </Grid>
            <Grid item xs={12} display="flex" flexDirection="column">
              <InputLabel>Billing to</InputLabel>
              {billingDetails ? (
                <>
                  <Typography variant="body1">
                    {`${billingDetails?.firstName || ''} ${billingDetails?.lastName || ''}`}
                  </Typography>
                  <Typography variant="caption">{billingDetails?.email || ''}</Typography>
                  <Typography variant="caption">{formatAddress(billingDetails?.billingAddress)}</Typography>
                </>
              ) : (
                <Typography variant="caption">Not available</Typography>
              )}
              <Button
                onClick={() => setBillingDetailsDialogOpen(true)}
                variant="text"
                data-testid={`${testIdPrefix}ChangeBillingDetailsButton`}
                sx={{ pl: 0, maxWidth: '156px' }}
              >
                Change Billing Details
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
                  <SectionTitle>Notes</SectionTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <ControlledTextField
                    control={control as unknown as Control<FieldValues, object>}
                    name="notes"
                    fullWidth
                    multiline
                    minRows="5"
                    label="Payment Notes"
                    error={errors?.notes?.message != null}
                    helperText={errors?.notes?.message}
                    testId={`${testIdPrefix}Notes`}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose(false)} data-testid={`${testIdPrefix}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            fromRef?.current?.requestSubmit();
          }}
          data-testid={`${testIdPrefix}SubmitButton`}
        >
          Submit Payment
        </Button>
      </DialogActions>

      {addPaymentModalOpen && (
        <AddPaymentMethodDialog
          memberId={customer.memberKey}
          open={addPaymentModalOpen}
          billingDetails={defaultBillingDetails}
          refetchPaymentMethodList={refetchPaymentMethodList}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          onClose={() => setAddPaymentModalOpen(false)}
          testId={`${testIdPrefix}Dialog`}
        />
      )}
      {billingDetailsDialogOpen && (
        <BillingDetailsDialog
          open={billingDetailsDialogOpen}
          validateOnOpen={forceBillingValidation}
          billingDetails={{
            firstName: billingDetails?.firstName,
            lastName: billingDetails?.lastName,
            email: billingDetails?.email,
            streetAddress: billingDetails?.billingAddress?.addressLine1 || '',
            aptNumber: billingDetails?.billingAddress?.addressLine2 || '',
            city: billingDetails?.billingAddress?.city || '',
            state: billingDetails?.billingAddress?.state || '',
            stateCode: billingDetails?.billingAddress?.stateCode || '',
            zipCode: billingDetails?.billingAddress?.postalCode || '',
            country: billingDetails?.billingAddress?.countryCode || '',
          }}
          handleBillingDetailsChange={handleBillingDetailsChange}
          handleClose={handleCloseBillingDetailsDialog}
          testId={`${testIdPrefix}BillingDetailsDialog`}
        />
      )}
    </Dialog>
  );
};
