import { DataGridPro as MUIDataGrid, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';

import { CustomPagination } from './CustomPagination';
import { useStyles } from './dataGridStyling';

interface CustomDataGridProps extends DataGridProProps {
  columns: GridColDef[];
  disablePaginationButtons?: boolean | null;
  useDefaultPagination?: boolean;
}

export const DataGrid = (props: CustomDataGridProps) => {
  const classes = useStyles();

  return (
    <MUIDataGrid
      {...props}
      rows={props.rows}
      columns={props.columns}
      disableColumnMenu={props.disableColumnMenu || true}
      autoHeight={props.autoHeight || true}
      initialState={{
        ...props.initialState,
        pagination: {
          ...props.initialState?.pagination,
          paginationModel: {
            page: props.initialState?.pagination?.paginationModel?.page || 0,
            pageSize: props.initialState?.pagination?.paginationModel?.pageSize || 25,
          },
        },
      }}
      rowHeight={props.rowHeight || 60}
      columnHeaderHeight={props.columnHeaderHeight || 30}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick || true}
      slots={{
        ...props.slots,
        pagination: props.useDefaultPagination
          ? undefined
          : () => <CustomPagination disablePaginationButtons={props.disablePaginationButtons} />,
      }}
      slotProps={{
        ...props.slotProps,
      }}
      className={classes.root}
    />
  );
};
