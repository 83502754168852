import { Fragment } from 'react';

import { alpha, Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { AirOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Air/AirOrderItemSummary';
import { Itinerary } from '@one/api-models/lib/Admin/Sales/Travel/Air/Itinerary';
import { Traveler } from '@one/api-models/lib/Admin/Sales/Travel/Air/Traveler';

import { DataDisplay } from 'common';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { OrderItemPricingSummary } from 'components/views/salesOrders/components/OrderDetails/OrderItemPricingSummary';

import { AGE_BAND_TYPE, AIR_TRIP_TYPE } from '../../constants/orderValues';

interface Props {
  orderItem: AirOrderItemSummary;
  purchaseSummary?: PurchaseSummary;
}

export const AirOrderItemDetails = ({ orderItem, purchaseSummary }: Props) => {
  const { productSummary, reservationReference } = orderItem;
  const { formatDate: _formatDate } = useFormat();
  const formatDate = (date: string | Date | null | undefined, format = 'dd MMM yyyy'): string => {
    if (!date) return 'N/A';
    return _formatDate(new Date(date!), true, format) ?? 'N/A';
  };

  return (
    <>
      <SectionTitle title="Overview" variant="h6" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay label="Name" value={productSummary.name} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Trip Type" value={AIR_TRIP_TYPE[productSummary.tripType]} />
        {reservationReference && (
          <>
            <Divider orientation="vertical" flexItem />
            <DataDisplay label="Record Locator" value={reservationReference} />
          </>
        )}
      </Box>
      <SectionTitle title="Flight details" variant="h6" sx={{ mt: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: '100%',
          mb: 2,
        }}
      >
        {orderItem.productSummary.itineraries?.map((itinerary, index) => (
          <Box sx={{ mb: index !== productSummary.itineraries.length - 1 ? 1 : 0 }} key={index}>
            <ItineraryOverview itinerary={itinerary} />
          </Box>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {orderItem.travelers.map((traveler: Traveler, index: number) => (
          <Grid item xs={12} sm={6} md={4} key={`traveler_${index}`}>
            <SectionTitle title={`Traveler ${index + 1} (${AGE_BAND_TYPE[traveler.ageBandType]})`} variant="h6" />
            <Box display="flex" flexDirection="column" gap={1} sx={{ wordBreak: 'break-word' }}>
              <DataDisplay label="Name" value={`${traveler.firstName} ${traveler.lastName}`} />

              {traveler.dateOfBirth && <DataDisplay label="Date of birth" value={formatDate(traveler.dateOfBirth)} />}
              {traveler.email && <DataDisplay label="Email" value={traveler.email} />}
              {traveler.phone && <DataDisplay label="Phone" value={traveler.phone} />}
              {!!traveler.hasDeclinedToProvideContactInfo && (
                <Typography
                  fontSize="0.875rem"
                  fontWeight={400}
                  maxWidth="250px"
                  sx={{ opacity: 1, color: (theme) => alpha(theme.palette.common.black, 0.7) }}
                >
                  The traveler has declined to provide his contact information to the airline.
                </Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      {purchaseSummary && (
        <Box sx={{ my: 4 }}>
          <OrderItemPricingSummary purchaseSummary={purchaseSummary} orderItem={orderItem} />
        </Box>
      )}
    </>
  );
};

const ItineraryOverview = ({ itinerary }: { itinerary: Itinerary }) => {
  const { formatDate } = useFormat();
  const originLocation = itinerary.segments[0].origin;
  const destinationLocation = itinerary.segments[itinerary.segments.length - 1].destination;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: { xs: 'start', sm: 'center' },
        justifyContent: 'space-between',
        mr: { md: 2 },
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { md: '280px', lg: '300px' },
        }}
      >
        <Typography fontSize="0.875rem" fontWeight={600}>
          {formatDate(originLocation.localTime, false, 'EEE, MMM d, yyy')}
        </Typography>
        <Typography fontWeight={600}>
          {`${formatDate(originLocation?.localTime, true, 'hh:mma')?.toLowerCase()} `}-
          {` ${formatDate(destinationLocation?.localTime, true, 'hh:mma')?.toLowerCase()}`}
        </Typography>
        <Typography
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, fontSize: '0.875rem', color: '#5a6872' }}
        >
          {getMarketingAirlineNames([itinerary]).join(', ')}
        </Typography>

        <Typography variant="body2" sx={{ display: { xs: 'block', md: 'none' } }}>
          {originLocation.airportCode}-{destinationLocation.airportCode} (
          {formatDuration(itinerary.totalDurationInMinutes)}
          {itinerary.segments.length > 1 &&
            `, ${itinerary.segments.length - 1} ${itinerary.segments.length - 1 === 1 ? 'stop' : 'stops'}`}
          )
        </Typography>
      </Box>
      <Box mx={0.5} sx={{ display: { xs: 'none', md: 'block' }, minWidth: '75px' }}>
        {itinerary.numberOfStops > 0 ? (
          <>
            <Typography fontWeight={500} fontSize="0.875rem" lineHeight={1.3}>
              {`${itinerary.numberOfStops} ${itinerary.numberOfStops === 1 ? 'stop' : 'stops'}`}
            </Typography>
            <Typography lineHeight={1.3}>
              {itinerary.segments.map((segment, index) => (
                <Fragment key={index}>
                  {segment.preFlightLayoverDurationInMinutes && (
                    <Tooltip
                      title={`${formatDuration(segment.preFlightLayoverDurationInMinutes)} layover in ${
                        segment.origin.locationName
                      }`}
                      arrow
                    >
                      <Typography component="span" fontSize="0.875rem" sx={{ color: '#5a6872', cursor: 'initial' }}>
                        {segment.origin.airportCode}
                        {index !== itinerary.segments.length - 1 ? ', ' : ''}
                      </Typography>
                    </Tooltip>
                  )}
                </Fragment>
              ))}
            </Typography>
          </>
        ) : (
          <Typography fontWeight={500} fontSize="0.875rem">
            Nonstop
          </Typography>
        )}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' }, minWidth: '75px' }}>
        <Typography fontWeight={500} fontSize="0.875rem" lineHeight={1.3}>
          {formatDuration(itinerary.totalDurationInMinutes)}
        </Typography>
        <Box sx={{ display: 'flex', fontSize: '0.875rem' }}>
          <Tooltip arrow title={originLocation.locationName}>
            <Typography sx={{ color: '#5a6872', fontSize: '0.875rem' }}>{originLocation.airportCode}</Typography>
          </Tooltip>
          -
          <Tooltip arrow title={destinationLocation.locationName}>
            <Typography sx={{ color: '#5a6872', fontSize: '0.875rem' }}>{destinationLocation.airportCode}</Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}h ${minutesLeft}m`;
};

export const getMarketingAirlineNames = (itineraries: Itinerary[]) => {
  const airlineNames: Set<string> = new Set();

  itineraries.forEach((itinerary) => {
    itinerary.segments.forEach((segment) => {
      if (segment.marketingAirline?.name) {
        airlineNames.add(segment.marketingAirline.name);
      }
    });
  });

  return Array.from(airlineNames);
};
